.emails-tab-conversation {
  &-outer-container {
    height: 65vh;
    width: 95%;
    overflow: hidden;
  }
  &-inner-container {
    height: 65vh;
    width: 95%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
.emails-image {
  width: 30px;
  height: 30px;
  border-radius: 15px;

}

.emoji {
  position: absolute;
  width:  auto;
  height: 100%;
  bottom: 330px;
}

.emails-write-message {
  width: 95%;
  height: 45px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  &-input {
    width: 80%;
    height: 30px;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 30px;
    color: #9b9b9b;
  }
  &-attachment {
    width: 14px;
    height: 15px;
  }
}
.emails-send-button {
  width: 86px;
  height: 45px;
  color: white;
  background: #7da5c2;
  border-radius: 5px;
}

@media only screen and (max-width: 650px) {
  .emails-write-message {
    width: 90%;
  }
}
