.info-tab-heading{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    
    color: #4A4A4A;
    
}
.info-tab-details{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    
    color: #000000;
}

.info-tab-pencil {
    height: 25px;
    width: 25px;
}

.info-tab-save {
    height: 40px;
    padding: 0px 15px;
    border-radius: 5px;
    background-color: #7DA5C2;
    cursor: pointer;
    
    &-container {
        margin-top: 10px;
    }
}

.info-tab-input {
    width: 90%;
    height: 35px;
    border: solid 1px #DCDCDC;
    border-radius: 10px;
    padding: 0px 10px;
    text-align: end;
    margin-top: 5px;

    &-buyer {
        @extend .info-tab-input;
        width: 100%;
        text-align: start;
    }

    &-contact {
        @extend .info-tab-input;
        text-align: start;
    }
}

.info-tab-datum {
    &-dropdown {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
        padding: 10px 15px;
        display: flex;
        position: absolute;
        left: 0px;
        top: calc(100% + 15px);
    }

    &-line {
        height: 100%;
        width: 2px;
        background: #DCDCDC;
        margin: 0px 15px;
    }
}