.custom {
  &-select-label {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 38px;
    color: #6f6f6f;
  }
  &-select-box {
    width: 90%;
    height: 45px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    border: 1px solid #dadada;
    border-radius: 10px;
    padding: 10px;
    outline: none;
  }
  &-error-border {
    border: 1px #f30c0c solid;
  }
}
