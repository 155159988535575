.dashboard-bgcolor {
    background-color: #F2F2F2;
    height: 100vh;
}

.dashboard-section-vh {
    min-height: 100vh;
}

.dashboard-menu-button {
    height: 50px;
    width: 50px;
    padding: 5%;
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #DCDCDC;
    border-radius: 10px;
}
.dashboard-sidebar-toggle {
    position: absolute;
    z-index: 1;
}
