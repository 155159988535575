.custom {
  &-input-label {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 25px;
    color: #6f6f6f;
  }
  &-input-box {
    width: 90%;
    height: 45px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    border: 1px solid #dadada;
    border-radius: 10px;
    padding: 10px;
    outline: none;
    margin-top: 5px;
  }
  &-error-border {
    border: 1px #f30c0c solid;
  }
  &-thickness {
    &-container {
      width: 50%;
      height: 45px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      border: 1px solid #dadada;
      border-radius: 10px;
      padding: 10px;
      background: #ffffff;
      margin-top: 5px;
    }
    &-disable-container{
      width: 40%;
      border: none;
      background: none;
      margin-top: 5px;
    }
    &-input{
        width: 80%;
      height: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      padding: 10px;
    }
  }
}

input:disabled{
  border: none;
  background: none;
  padding: 0px;
  height: 15px;
  margin-top: 0px;
}