.project-application-contactinfo-edit {
        line-height: 1.5rem;
        border-radius: 10px;
        padding: 8px 6px;
        background-color: #ffffff;
        color: #000000;
        font-size: 14px;
        width: 100%;
        border: 1px solid #DCDCDC;
}
