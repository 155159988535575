.step-four {
  &-header {
    width: 100%;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #4a4a4a;
    overflow-wrap: break-word;
  }
  &-data {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    overflow-wrap: break-word;
  }
  &-add-image {
    &-size {
      width: 15px;
      height: 15px;
    }
    width: 35px;
    height: 35px;
    margin-left: -5px;
    border-radius: 5px;
    background: #0066a0;
  }
  &-delete-image {
    width: 35px;
    height: 35px;
    margin-left: -5px;
  }
  &-info{
    &-modal{
      position: absolute;
      width: 50vw;
      border-radius: 5px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
      background: #ffffff;
      margin-left: 18px;
      padding: 20px;
      display: flex;
      align-content: flex-end;
      z-index: 10px;
      margin-top: 3px;
      display: flex;
      flex-direction: column;
      align-self: center;
    }
    &-arrow {
      height: 15px;
      width: 23.78px;
      margin-top: 3px;
      z-index: 1;
      transform: rotate(270deg);
    }
    &-highlight-box{
      width: auto;
      border: 1px solid #dedede ;
      padding: 10px;
      margin: 5px;
    }

    &-marker-disable {
      list-style-type: none;
    }
  }
}
