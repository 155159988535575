.residence{
&-grid-height{
  height: 100vh;
}
&-sidebar-style{
  z-index: 6;
}
 &-half-grid-height{
  height: 43vh;
  overflow: hidden;
}
  &-line-between {
    width: 0px;
    height: 85vh;
    // margin-top: 25px;
  
    border: 1px solid #aab7c4;
    background-color: #aab7c4;
  }
}
@media only screen and (max-width: 900px) {
  .residence-line-between {
    width: 90%;
    height: 0px;
    margin-left:5%;
    margin-bottom: 10%;
  }
}

@media only screen and (max-width: 600px) {
  .residence-line-between {
    width: 100%;
    height: 0px;
    margin-left:0px;
    margin-bottom: 10%;
  }

  .residence {
    &-half-grid-height {
      height: auto;
      padding: 15px;
    }
  }
}
