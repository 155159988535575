.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-shrink-disable {
  flex-shrink: 0;
}

.flex-grow-disable {
  flex-grow: 0;
}

.horizontal-center {
  display: flex;
  justify-content: center;
}

.horizontal-start {
  display: flex;
  justify-content: flex-start;
}

.horizontal-end {
  display: flex;
  justify-content: flex-end;
}

.horizontal-between {
  display: flex;
  justify-content: space-between;
}

.horizontal-even {
  display: flex;
  justify-content: space-evenly;
}

.horizontal-around {
  display: flex;
  justify-content: space-around;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.vertical-start {
  display: flex;
  align-items: flex-start;
}

.vertical-end {
  display: flex;
  align-items: flex-end;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;

  &-origin {
    @extend .absolute;
    left: 0%;
    top: 0%;
  }
}

.fixed {
  position: fixed;
}

.set-image {
  background-repeat: no-repeat;
  background-position: center;

  &--contain {
    @extend .set-image;
    background-size: contain;
  }

  &--cover {
    @extend .set-image;
    background-size: cover;
  }
}

.object-fit {
  &-contain {
    object-fit: contain;
  }
  &-cover {
    object-fit: cover;
  }
  &-fill {
    object-fit: fill;
  }
}

.outline {
  &-none {
    outline: none;
  }
}

.full-container {
  height: 100vh;
  width: 100vw;
}

.overflow {
  &-hidden {
    overflow: hidden;
  }
  &-x-hidden{
    overflow-x: hidden;
  }
  &-y-hidden{
    overflow-y: hidden;
  }
  &-x-scroll {
    overflow-x: scroll;
  }
  &-y-scroll {
    overflow-y: scroll;
  }
  &-x-auto {
    overflow-x: auto;
  }
  &-y-auto {
    overflow-y: auto;
  }
}

.float-item {
  &-right{
    float: right;
  }

  &-left {
    float: left;
  }
}
