  .project-application-step-blue {
  width: 167px;
  height: 88px;
  background-color: #193e5d;
  color: #ffffff;
  border-radius: 15px;
  border: 1px solid #cecdce;
  // min-width: 13%;
  margin-bottom: 20px;
  padding: 10px;
  cursor: pointer;
}

.project-application-step-outline {
  width: 167px;
  height: 88px;
  border: 1px solid #193E5D;
  color: #9b9b9b;
  background-color: #ffffff;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 10px;
  cursor: not-allowed;
}

.project-application-step-hr {
  width: 40px;
  height: 1px;
  background-color: #193e5d;
  margin-bottom: 20px;
}

@media only screen and (min-width:960px) and (max-width: 1249px) {
    .project-application-step-blue {
        width: 130px;
        height: 80px;
        font-size: 13px;
      border-radius: 50%;
      // margin-bottom: 0px;
    }
  
    .project-application-step-outline {
        width: 130px;
        height: 80px;
        font-size: 12px;
      border-radius: 50%;
    }
    .project-application-step-hr {
        width: 30px;
    }

  }
@media only screen and (min-width: 671px) and (max-width: 960px) {
  .project-application-step-blue {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    // margin-bottom: 0px;
  }

  .project-application-step-outline {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
  .project-application-step-hr {
    width: 6.5%;
  }
}

@media only screen and (min-width: 421px) and (max-width: 670px) {
  .project-application-step-blue {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    // margin-bottom: 0px;
  }

  .project-application-step-outline {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .project-application-step-hr {
    width: 4%;
  }
}

@media only screen and (max-width: 420px) {
  .project-application-step-blue {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    // margin-bottom: 0px;
  }

  .project-application-step-outline {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .project-application-step-hr {
    width: 3%;
  }
}

@media only screen and (max-width: 315px) {
  .project-application-step-blue {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    // margin-bottom: 0px;
  }

  .project-application-step-outline {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .project-application-step-hr {
    width: 3%;
  }
}
