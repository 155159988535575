.step-five {
  &-upload-button {
    width: 300px;
    height: 42px;
    // justify-content: center;
    // align-items: center;
  }
  &-definite-submit{
      background: #009DA0;
  }
}
